@use '../abstracts/mixins' as *;
@use '../abstracts/font-faces' as *;

h3.category {
  margin-top: 4rem;
  margin-bottom: 1rem;

  &:first-of-type{
    margin-top: 0;
    color: transparent;
  }

  @include x-media($width: max, $break: md){
    &:first-of-type{
      margin-top: 40px;
    }
  }

}

div#categories-spied {
  @include x-media($width: max, $break: md){
    div.lang p{
      display: none;
    }
  }
    
}

.group {
  position: relative;

  .group__text {
    display: none;
  }

  @include x-media($width: max, $break: md){
    .group__text {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      color: whitesmoke;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 0 0 25% 0;

      .group__text-frame{
        padding: 2rem;
        p {
          font-size: 1.5rem;
        }
      }
    }
  }

  &:hover .group__text {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0 0 25% 0;

    .group__text-frame{
      padding: 2rem;
      p {
        font-size: 1.5rem;
      }
    }
  }
}

swiper-container.illustration{
  /* height: 100%; */

  swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: max-content; */
  }

  /* @media screen and (min-width: 768px) { */
  /*   swiper-slide { */
  /*     width: auto !important; */
  /*   } */
  /* } */

  swiper-slide img {
    display: block;
    // width: 100%;
    height: 100%;
    max-height: 750px;
    // width: 650px;
    width: 100%;
    object-fit: contain;
    /* width: auto; */
    max-width: 100%;
  }

  /* @media screen and (min-width: 768px) { */
  /*   swiper-slide img { */
  /*     height: auto; */
  /*     width: 100%; */
  /*     max-width: 100%; */
  /*   } */
  /* } */

}

#appRoot {
  position: relative;
}

#category-nav ul {
  --bs-nav-link-color: var(--bs-secondary);
  --bs-nav-pills-link-active-color: var(--bs-white);
  --bs-nav-pills-link-active-bg: var(--bs-secondary);
}

/* Apply the custom variables to the 'a' elements inside the ul */
#category-nav ul a {
  color: var(--bs-nav-link-color);
  background-color: var(--bs-nav-pills-link-active-bg);
  background-color: var(--bs-white);
  font-size: 1.5rem;
}

/* Add hover and active states for better visual feedback */
#category-nav ul a:hover,
#category-nav ul a:focus,
#category-nav ul a.active {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

#category-nav{
  position: fixed;
  top: 50px;
  right: 0;
  z-index: 100;
  opacity: 1;
  display: flex;
  justify-content: flex-end;
}

/* Large devices (desktops, 992px and up) */
@include x-media($width: min, $break:md){
  #category-nav{
    right: 3.5%;
    top: 55px;
  }
}

/////////////////////////////////////////
/////////////////////////////////////////
/////////////////////////////////////////



#category-nav-basic ul {
  /* --bs-nav-link-color: var(--bs-gray-300); */
  --bs-nav-link-color: #c8c5be;
  --bs-nav-pills-link-active-color: var(--bs-black);
  --bs-nav-pills-link-active-bg: var(--bs-white);
  --bs-nav-pills-border-radius: 0;
}

.category-nav-basic-wrapper {
  background-color: var(--bs-white);
  position: fixed;
  top: 45px;
  width: 100%;
  z-index: 100;
}

/* Large devices (desktops, 992px and up) */
@include x-media($width: min, $break:md){
  .category-nav-basic-wrapper{
    top: 50px;
  }
}

/* Apply the custom variables to the 'a' elements inside the ul */
#category-nav-basic ul a {
  background-color: var(--bs-white);
  font-size: 1.8rem;
  border: none;
  padding-left: 0;
}

/* Large devices (desktops, 992px and up) */
@include x-media($width: min, $break:md){
  #category-nav-basic ul a {
    font-size: 2rem;
  }
}

#category-nav-basic ul a{
  color: var(--bs-gray-500);
}

/* Add hover and active states for better visual feedback */
#category-nav-basic ul a:hover,
#category-nav-basic ul a:focus,
#category-nav-basic ul a.active {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

#category-nav-basic{
  display: flex;
  justify-content: flex-start;
  font-family: $font-family-title;
}

