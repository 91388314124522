@use "../abstracts/mixins" as *;

footer {
  margin-top: 1rem !important;
  clear: both;
  text-align: left;
  background-color: #f8f9fa;

  ul{
    padding-left: 0;
  }

  .footer-center{ // todo to delete
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  img {
    display: inline-block;
    vertical-align: text-bottom;
  }

  ul.language {
    padding-left: 0;

    li {
      display: inline-block;
      padding-right: 1rem;

      /* &:first-child { */
      /*   margin-left: 22px; */
      /* } */
    }
  }

  nav {
    padding: 1em 0;
  }

  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

    @include x-media($width:max, $break:md){
        .nav-container{
          flex-direction: column;
        }
    }

  .nav-group {
    display: flex;
    justify-content: space-between;
  }

  .nav-group-1 {
    /* flex-direction: column; */
    flex-direction: row;
    gap: 2rem;
  }

  @media screen and (min-width: 768px) {
    .nav-group-1 {
      border-bottom: 1px solid f_color('neutral', 200);
    }
  }

  .nav-item {
    list-style: none;
    // margin: 0.5em 0;
  }

  .nav-link {
    text-decoration: none;
    color: #007bff;
  }

  .nav-link:hover {
    text-decoration: underline;
  }

  @media (min-width: 768px) {
    .nav-group-1 {
      flex-direction: row;
    }

    .nav-group-1 .nav-item {
      margin: 0 1em 0 0;
    }
  }

  ul.footer--social-icons {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    gap: 1rem;
    border: 1px rgba(0,0,0,0.1) solid;
    border-radius: 50px;
    padding: 1em;

    img.logo {
      height: 2.5rem;
      // background-color: rgba(0, 0, 0, .02)
    }

    a {
      color: white;
    }
  }
}
