@use '../abstracts/mixins' as *;

div.about {

  img {
    margin: 0 5rem 2.5rem 0;
    float: left;
  }

  @include x-media($width: max, $break: sm){
    div {
      clear: both;
      margin: 0 auto;
    }

    div.lang{
      padding: 0 8px 0 8px;
    }
  }

  /* @media only screen and (max-width: 650px) { */
  /*   div{ */
  /*     clear: both; */
  /*     margin: 0 auto; */
  /*   } */
  /* } */

  h3.about-heading {
    margin: 4rem 0;
  }
}
