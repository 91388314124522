@use './root' as *;

body {
  font-family: var(--font-family-body);
  font-size: var(--font-size-500);
}

.dropdown-item {
  font-size: var(--font-size-500);
}

nav.navbar-brand > a{
  font-size: var(--font-size-800) !important;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  font-family: var(--font-family-title);
  font-weight: normal;
  font-stretch: 100%;
  line-height: 1.1;
}

h1,
.h1 {
  font-size: var(--font-size-800);
}

h2,
.h2 {
  --flow-spacer: 1.5em;
  font-size: var(--font-size-700);
}

h3,
.h3 {
  --flow-spacer: 1.5em;
  font-size: var(--font-size-600);
}

h4,
.h4 {
  font-size: var(--font-size-500);
}

footer p.copyright {
  font-size: var(--font-size-400);
  margin-bottom: 1rem;
}

// todo: to uncomment
/*

body {
  font-size: var(--font-size-400);
  font-family: 'Alaska', sans-serif;
  color: var(--clr-neutral-900);
  letter-spacing: -.5px;
}

h1, h2, h3, h4 {
  --font-weight: 700;
  --font-width: 80;
  font-weight: normal;
  font-stretch: 100%;
  line-height: 1.1;
  color: var(--clr-neutral-800);
}

h1 {
  --font-weight: 900;
  color: var(--clr, var(--clr-primary-400));
}

h1, .h1 {
  font-size: --font-size-800;
}

h2,.h2 {
  --flow-spacer: 1.5em;
  font-size: --font-size-700;
}

h3,.h3 {
  --flow-spacer: 1.5em;
  font-size: --font-size-600;
}

h4,.h4 {
  font-size: --font-size-500;
}

small,.text-small {
  font-size: var(--font-size-300);
}

strong {
  --font-weight: 500;
  font-weight: normal;
}

a {
  color: var(--clr-primary-400);

  &:hover,
  &:focus {
    color: var(--clr-primary-500);
    text-decoration: none;
  }
}

p:not([class]),
ul:not([class]),
li:not([class]) {
  max-width: 60ch;
}

 */
