$sizes: (
    4: 0.25rem,
    8: 0.5rem,
    12: 0.75rem,
    16: 1rem,
    20: 1.25rem,
    24: 1.5rem,
    28: 1.75rem,
    32: 2rem,
    36: 2.25rem,
    40: 2.5rem,
    44: 2.75rem,
    48: 3rem,
);


$hero-padding: clamp(15rem, 15vh, 20rem);
$section-padding: clamp(3rem, 10vh, 7rem);

$card-body-padding: clamp(2rem, 3.5vw, 5rem);

$grid-gap: 1.5rem;
$flex-group-gap: 1rem;

$container-inline-padding: 1rem;
$container-max-width: 60rem;
$container-max-width-wide: 75rem;

$xs-size: 1rem; // f_size(16); // 1rem; // 16
$s-size: 1.2rem; // f_size(20); // 1.2rem; // 20
$m-size: 1.6rem; // f_size(24); // 1.6rem; // 24
$l-size: 2.5rem; // f_size(40); // 2.5rem; // 40
$xl-size: 4.8rem; // f_size(76); // 4.8rem; // 76
