.fake-masonry {
    --gap: clamp(1rem, 5vmin, 2rem);
    columns: 250px;
    gap: var(--gap);
    // width: 96%;
    // max-width: 960px;
    max-width: 100%;

    margin: 5rem auto;
}

.fake-masonry>* {
    break-inside: avoid;
    margin-bottom: var(--gap);
}
