@use "breakpoints" as *;

@mixin x-media($width: min, $break: xs) {
  @if ($width == min) {
    @media only screen and (min-width: map-get($grid-min-breakpoints, $break)) {
      @content
    }
  } @else {
    @media only screen and (max-width: map-get($grid-max-breakpoints, $break)) {
      @content
    }
  }
}
