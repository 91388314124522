/*top menu button*/
.glyphicon {
  color: #47717f;
  font-size: 1.025em;

}

h3.mural-heading {
  margin: 1em 0;
}

h4.mural-text {
  margin: 1.5rem 0 .75rem;
}

/* Large devices (desktops, 992px and up) */
@media screen and (min-width: 992px) {
  h3.mural-heading {
    /* margin: 2em 8rem; */
    margin: 2em 0 1em;
  }

  h4.mural-text {
    margin: 1rem 0 0 0;
  }
}


.super-slider-super-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  .super-slider-container {
    position: relative;
    width: 100%;
    height: 750px;
  }
}

/* // todo: to revise */
/* swiper-container { */
/*   width: 100%; */
/*   height: 100%; */
/* } */

swiper-container::part(bullet-active) {
  background-color: rgb(51, 51, 0);
}

swiper-container::part(button-prev),
swiper-container::part(button-next) {
  color: white;
}

swiper-container.mural{

  swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    width: 100%
  }

  @media screen and (min-width: 768px) {
    swiper-slide {
      width: auto !important;
    }
  }

  swiper-slide img {
    display: block;
    // width: 100%;
    // height: 100%;
    height: 750px;
    // width: 650px;
    // width: 100%;
    width: auto;
    /* width: 100%; */
    max-width: 100%;
    object-fit: cover;
  }

  @media screen and (min-width: 768px) {
    swiper-slide img {
      height: auto;
      width: auto;
      /* width: 100%; */
      max-width: 100%;
    }
  }
}
@media screen and (min-width: 768px) {
  swiper-container.mural {
    padding-left: 129px;
  }
}

.mural-video-header{
  position:relative;

  .mural-video-header__text{
    position: absolute;
    height:100%;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1{
      font-family: "TheanoDidot";
      margin-bottom: 1rem;
      font-size: var(--font-size-700);
    }
    h2{
      font-family: "TheanoDidot";
      font-style: italic;
      font-size: var(--font-size-600);
    }
    /* Small devices (landscape phones, 576px and up) */
    @media screen and (max-width: 576px) {
      h1, h2{
        margin-bottom: 1em;
      }
      h2{
        /* font-size: var(--font-size-400) */
        font-size: 1.9rem;
      }
    }
    /* #arrow-down-svg { */
    /*   position: absolute; */
    /*   bottom: 0; */
    /*   left: 50%; */
    /*   transform: translateX(-50%); */
    /*   width: 45px; */
    /*   height: 75px; */
    /*   fill: white; */
    /*   animation: bounce 2s infinite; */
    /* } */


    svg#arrow-down-svg {
      position: absolute;
      bottom: -1em;
      left: 50%;
      /* transform: translateX(-50%); */

      display: inline-block;
      width: 45px;
      text-align: center;
      animation: bounce 10s infinite;
    }

    /* 45px down */
    @keyframes bounce {
      0%, 20%, 50%, 80%, 100% {
        transform: translate(-50%, 0px); // 0px
      }
      40% {
        transform: translate(-50%, -30px); // -30px
      }
      60% {
        transform: translate(-50%, -15px); // -15px
      }
    }


  }
}
