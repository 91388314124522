@use '../abstracts/mixins' as *;
@use '../abstracts/font-faces' as *;

header{
  /* padding: 25px; */
  font-family: $font-family-menu !important;
  --dark-menu-item: #212120;
}

@media screen and (min-width: 768px){
  header{
    padding: 25px;
  }
}

/* to display navbar link and button or text menu separately */
nav.navbar .container-fit{
  display: flex;
  justify-content: space-between;
}

nav.navbar ul{
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
}

a.navbar-brand {
  font-size: 20px;
  font-weight: 400;
  font-family: 'Fjalla One', Geneva, sans-serif;
  color: var(--dark-menu-item);
}

/* Large devices (desktops, 992px and up) */
/* @media screen and (min-width: 992px) { */
/*   a.navbar-brand { */
/*     font-size: 20px; */
/*   } */
/* } */

@include x-media($width: min, $break:md){
  a.navbar-brand {
    font-size: 25px;
  }
}

nav.navbar ul>li {
  font-size: 16px;
}

/* Large devices (desktops, 992px and up) */
@media screen and (min-width: 992px) {
  nav.navbar ul>li {
    font-size: 18px;
  }
}


body.mural{
  #navbarMainMenu > ul > li:nth-child(1) > a{
    color: var(--dark-menu-item);
  }
}

body.illustration{
  #navbarMainMenu > ul > li:nth-child(2) > a{
    color: var(--dark-menu-item);
  }
}

body.about{
  #navbarMainMenu > ul > li:nth-child(3) > a{
    color: var(--dark-menu-item);
  }
}

/* offcanvas */

.offcanvas-header {
  margin: 0 8px;
}

.offcanvas-body {

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 30px 30px 30px;

  ul.navbar-nav {
    max-width: min-content;
    margin: auto;
  }

  ul.footer--social-icons {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    gap: 2rem;
    border: 1px rgba(0,0,0,0.1) solid;
    border-radius: 50px;
    padding: 1em;

    img.logo {
      height: 2.5rem;
      // background-color: rgba(0, 0, 0, .02)
    }

    a {
      color: white;
    }
  }
}

.offcanvas-footer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* dropdown version */

/* .offcanvas-body .dropdown-menu { */
/*   display: block; */
/*   position: relative; */
/*   opacity: 1; */
/*   visibility: visible; */
/*   border: none; */
/*   box-shadow: none; */
/* } */
/**/
/* .offcanvas-body .dropdown-item { */
/*   padding-left: 2rem; */
/* } */
/**/
/* .offcanvas-body .nav-item.dropdown>a { */
/*   padding-right: 1.5rem; */
/* } */


/* .navbar-toggler-icon { */
/*   background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(33, 37, 41, 0.75)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); */
/* } */

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0,0,0,1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

ul.language {
  li {
    display: inline-block;
    padding-right: 1rem;
  }
}
