@use '../abstracts/colors' as *;
@use '../abstracts/font-scale' as *;
@use '../abstracts/font-faces' as *;
@use '../abstracts/mixins' as *;

:root {
    --font-family-body: #{$font-family-body};
    --font-family-title: #{$font-family-title};

    --section-spacing: 3.5rem;
    @include x-media($width:min, $break:lg){
        --section-spacing: 7rem;
    }

    @each $color, $shades in $colors {
        @each $shade, $value in $shades {
            --color-#{$color}-#{$shade}: #{$value};
        }
    }

    @each $screen-size, $font-sizes in $font-scale {
        @if $screen-size == small {
            @each $font-size, $value in $font-sizes {
                --font-size-#{$font-size}: #{$value};
            }
        } @else {
            @include x-media($width:min, $break:md){
                @each $font-size, $value in $font-sizes {
                    --font-size-#{$font-size}: #{$value};
                }
            }
        }
    }
}
