@use '../abstracts/colors' as *;
@use '../abstracts/sizes' as *;
@use '../abstracts/mixins' as *;

body {
    width: 100%;
  // background: $dark-blue;
  // color: $off-white;
  // width: 96%;
  // @include x-media($width: min, $break: md){
  //   width: 75%;
  // }
  // margin: 0 auto;
}

a:link {
  color: #676;
  text-decoration: none;
}

a:visited {
  text-decoration: none;
  color: #735f4b;
}

a:hover {
  text-decoration: none;
  color: #735f4b;
}

a:active {
  text-decoration: none;
  color: #735f4b;
}

header {
  h1 {
    font-size: 1.6rem;
    margin-left: $m-size;
  }
}

h1, h2, h3 {
  margin-bottom: 2em;
}

input {
    padding: 0 1em;
}

main {
  margin-bottom: 1.6rem;
  width: 95%;

  section#appRoot {
      /* width: 95%; */
      margin: 0 auto;

      h5.section-title{
          text-align: center;
          margin: 1rem auto 0;
          // translate: 25rem 1rem;
          // transform: translate(25rem, 1rem);
      }

      section.section-container {
          margin: 0 auto;
          border: 0.7px solid #808000;
          padding: 2rem;
          border-radius: 25px;

          img + span{
              display: inline-block;
              width: 130px;
              text-align: center;
              line-height: 80%;
          }
      }
  }
}

footer {
  color: #735f4b;
}
