/* 4 images */

/* @keyframes slidy { */
/* 0% { left: 0%; } */
/* 20% { left: 0%; } */
/* 25% { left: -100%; } */
/* 45% { left: -100%; } */
/* 50% { left: -200%; } */
/* 70% { left: -200%; } */
/* 75% { left: -300%; } */
/* 95% { left: -300%; } */
/* 100% { left: -400%; } */
/* } */

/* 5 images */
@keyframes slidy {
  0% {
    left: 0%;
  }

  19% {
    left: 0%;
  }

  20% {
    left: -100%;
  }

  39% {
    left: -100%;
  }

  40% {
    left: -200%;
  }

  59% {
    left: -200%;
  }

  60% {
    left: -300%;
  }

  79% {
    left: -300%;
  }

  80% {
    left: -400%;
  }

  99% {
    left: -400%;
  }

  100% {
    left: -500%;
  }
}

div.home-content {
  margin: 0;
  width: 100vw;
  height: 100vh;
}

// fade in out version
div.home-background-slider {
  overflow: hidden;
  position: relative;
  height: 100%;

  figure {
    position: relative; // Allow images to overlap
    height: 100%;
  }

  figure img {
    position: absolute; // Stack images on top of each other
    top: 0;
    left: 0;
    width: 100%; // Ensure the images cover the figure area
    height: 100%;
    object-fit: cover;
    opacity: 0; // Start with images hidden
    animation: fadeInOut 40s infinite; // Total cycle time is 30 seconds
  }

  figure img:nth-child(1) {
    animation-delay: 0s; // Start immediately
  }

  figure img:nth-child(2) {
    animation-delay: 5s; // Start after the first image fades out
  }

  figure img:nth-child(3) {
    animation-delay: 10s; // Start after the second image fades out
  }

  figure img:nth-child(4) {
    animation-delay: 15s; // And so on
  }

  figure img:nth-child(5) {
    animation-delay: 20s;
  }

  figure img:nth-child(6) {
    animation-delay: 25s;
  }

  figure img:nth-child(7) {
    animation-delay: 30s;
  }

  figure img:nth-child(8) {
    animation-delay: 35s;
  }

  @keyframes fadeInOut {
    0% {
      opacity: 0; // Hidden at the start
    }
    5% {
      opacity: 1; // Start fading in
    }
    25% {
      opacity: 1; // Fully visible
    }
    30% {
      opacity: 0; // Start fading out
    }
    100% {
      opacity: 0; // Hidden before next cycle
    }
  }
}

// sliding from the left version
//
// div.home-background-slider {
//   overflow: hidden;
//   position: relative;
// 
//   figure {
//     position: relative;
//     width: 600%;
//     margin: 0;
//     left: 0;
//     text-align: left;
//     font-size: 0;
//     animation: 20s slidy infinite ease-in;
// 
//     img {
//       width: 16.66%;
//       float: left;
//       height: 100vh;
//       object-fit: cover;
//       transition: opacity .3s ease-in-out;
//     }
//   }
// }

div.home-background-slider__text-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}

div.home-background-slider__text {
  /* position: absolute; */
  text-align: center;
  font-weight: 900;
  /* text-shadow: 0 0 8px black; */
  color: white;
  /* top: 25%; */
  /* left: 35%; */
  z-index: 10;
  /* max-width: 75%; */
  /* border: 3px dashed white; */
  /* background-image: linear-gradient(90deg,rgba(0,0,0,0),rgba(0,0,0,.1), rgba(0,0,0,.2), rgba(0,0,0,.3), rgba(0,0,0,.3),rgba(0,0,0,.3),rgba(0,0,0,.3),rgba(0,0,0,.3),rgba(0,0,0,.3),rgba(0,0,0,.3),rgba(0,0,0,.3),rgba(0,0,0,.2),rgba(0,0,0,.1),rgba(0,0,0,0)); */

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;

  /* & > * { */
  /*   padding: 16px; */
  /*   border-radius: 50%; */
  /*   background-color: rgba(0, 0, 0, 0.1); */
  /* } */

  h1 {
    font-size: 5rem;
  }

  h2 {
    font-size: 3.5rem;
  }

  ul {
    /* padding: 0; */
    /* border: 3px dashed blueviolet; */

    li {
      background-color: rgba(0, 0, 0, 0.02);
      /* border: 2px solid red; */
    }
  }

  a {
    color: white;
  }

  h1.home-background-slider__text-title{
    margin-bottom: 18vh;
    /* padding: 16px; */
    /* border-radius: 50%; */
    /* background-color: rgba(0, 0, 0, 0.1); */
  }

  ul.home-background-slider__text-menu {
    margin-bottom: 28vh;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: 5rem;
    /* padding: 16px; */
    /* border-radius: 15%; */
    /* background-color: rgba(0, 0, 0, 0.1); */
    letter-spacing: 0.1rem;
    font-size: 30px;

    h2{
      margin-bottom: 0;
    }
  }

  ul.home-background-slider__text-social {
    margin-bottom: 12vh;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    gap: 1rem;
    /* padding: 16px; */
    /* border-radius: 50%; */
    /* background-color: rgba(0, 0, 0, 0.1); */

    img.logo {
      height: 3rem;
      // background-color: rgba(0, 0, 0, .2)
    }
  }
}

@media only screen and (max-width: 600px) {
  div.home-background-slider__text {
    top: 10%;
    left: 5%;
    width: 90vw;

    h1 {
      font-size: 4rem;
      margin: 10rem 0 10rem;
    }

    h2 {
      font-size: 2.5rem;
    }

    ul.home-background-slider__text-menu {
      font-size: .8rem;
      gap: .5rem;
      letter-spacing: 0;
    }

    ul.home-background-slider__text-social {
      margin-bottom: 16vh;

      img.logo {
        height: 2.5rem;
      }
    }
  }
}

div.container-fluid {
  padding: 0;
}
