@use '../abstracts' as *;
@use "sass:map";

.illustration-modal {
  background: white;
  padding: 2px;
  position: absolute;
  /* top: 50%; */
  top: 51%;
  left: 50%;
  width: 97%;
  /* height: calc(100vh - 145px); */
  height: calc(100vh - 100px);
  transform: translate(-50%, calc(-50% + 20px));
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #ece8de;
}

/* requirement: plain effect */
.illustration-modal {
  border: none;
  box-shadow: none;
  outline: 275px solid #ece8de;
  padding: 0;
  width: 100%;

  button#cerrarDocumento {
    position: absolute;
    top: 12px;
    right: 16px;
    z-index: 10;
    color: dimgray;
    font-size: 25px;
  }

  @media (min-width: 768px) {
    swiper-container.illustration{
      width: 93%;
      height: 98%;
      margin: 0 auto;
    }
    button#cerrarDocumento {
      /* right: 55px; */
      right: 3.5%;
    }
  }

}

.modal-content {
  height: 100%;
}

/* .modal-content { */
/*     width: 100%; */
/*     margin: 0 auto; */
/*     border: none; */
/*     overflow: clip; */
/*     background-color: $dark-blue; */
/* } */
/**/
/* #cerrarDocumento { */
/*     display: block; */
/*     margin: 0 8px 0 auto; */
/*     text-align: right; */
/*     color: map.get($colors, "neutral", 200); */
/*     font-weight: bolder; */
/*     background-color: #333745; */
/*     padding: .7rem 1.4rem; */
/*     // font-size: $xs-size; */
/*     font-size: size(16); */
/*     border-radius: 2rem 2rem 0 0; */
/* } */

/* #text_desc, #undo_desc, #edit_desc{ */
/*     margin-left: 2rem; */
/* } */


@media (min-width: 768px) {
  swiper-slide {
    align-items: flex-start;
    height: 95%;
  }
}

.ReactModalPortal>div {
  opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
  transition: opacity 400ms ease-in-out;
  position: fixed;
  bottom: 0;
  height: 100% !important;
  z-index: 1000;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content--after-open {
  bottom: 0;
}
