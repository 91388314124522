@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

@font-face {
    font-family: 'Fjalla One';
    // src: url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');
    font-display: auto;
}

@font-face {
    font-family: 'Bebas Neue';
    // src: url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
    font-display: auto;
}

@font-face{
  font-family: 'TheanoDidot';
  src: url('../../src/font/theano-didot.ttf') format('truetype');
}

$font-family-title: 'Bebas Neue', Verdana, sans-serif;
$font-family-body: 'TheanoDidot', serif;
$font-family-menu: 'TheanoDidot', serif;
