@use '../abstracts' as *;

// Button

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;

	&:disabled{
        color: grey;
	}
}
