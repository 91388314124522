/* fullscreen */
.slider-fullscreen {
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

/* not fullscreen */
.slider {
    position: relative;
    margin: 0;
    top: 0;
    left: 0;
    height: 750px;
    margin: 1rem 0
    /* margin: 0 auto; */
}

.slide-set {
    position: relative;
    height: 750px;
    /* width: 300%; */
    overflow-x: hidden;
    display: flex;
    flex-flow: row nowrap;
}

.slide {
    background-size: cover;
    background-position: center;

    flex: 0 0 auto;
    /* position: relative; */
    /* top: 0; */
    /* left: 0; */

    /* width: 100%; */
    // width: 33%;
    height: 750px;
    margin: 0 auto;
}

@keyframes universal-slidy {
    0% {
        left: 0%;
    }

    20% {
        left: 0%;
    }

    25% {
        left: -50%;
    }

    45% {
        left: -100%;
    }

    50% {
        left: -100%;
    }

    70% {
        left: -150%;
    }

    75% {
        left: -150%;
    }

    95% {
        left: -200%;
    }

    100% {
        left: -200%;
    }
}

@keyframes universal-slidy-forwards {
    0% {
        transform: translateX(0%);
    }

    20% {
        transform: translateX(0%);
    }

    25% {
        transform: translateX(-10%);
    }

    45% {
        transform: translateX(-12%);
    }

    50% {
        transform: translateX(-15%);
    }

    70% {
        transform: translateX(-20%);
    }

    75% {
        transform: translateX(-25%);
    }

    95% {
        transform: translateX(-30%);
    }

    100% {
        transform: translateX(-33%);
    }
}

@keyframes universal-slidy-backwards {
    0% {
        transform: translateX(0%);
    }

    20% {
        transform: translateX(0%);
    }

    25% {
        transform: translateX(-10%);
    }

    45% {
        transform: translateX(-12%);
    }

    50% {
        transform: translateX(-15%);
    }

    70% {
        transform: translateX(-20%);
    }

    75% {
        transform: translateX(-25%);
    }

    95% {
        transform: translateX(-30%);
    }

    100% {
        transform: translateX(-33%);
    }
}

.slide-text {
    background-color: rgba(255, 255, 255, .7);
    width: 200px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding: 1rem;
}

.slide-text-hidden {
    display: none;
}

.leftButton {
    position: absolute;
    top: 50%;
    left: 8px;
    background-color: rgba(255, 255, 255, .2);
    border: none;
    z-index: 10000;
}

.rightButton {
    position: absolute;
    top: 50%;
    right: 8px;
    background-color: rgba(255, 255, 255, .2);
    border: none;
    z-index: 10000;
}
